<template>
	<div>
		<a-modal :title="getTitle" :width="480" :visible="visible" @cancel="handleCancel">
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div>
						<div class="flex center">
							<upload-img v-model="form.icon"></upload-img>
						</div>
						<div class="flex center mt8">
							<div class="ft12 ftw400 cl-notice">分类图标：建议上传200x200px</div>
						</div>
					</div>

					<div class="mt24">
						<a-tree-select v-model="form.pid" style="width: 100%"
									   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="category"
									   placeholder="Please select" >
						</a-tree-select>
					</div>
					<div class="flex  mt24">
						<a-input class="" style="width: 230px;" placeholder="请输入分类名称" v-model="form.name"
							:maxLength="20">
						</a-input>
						<a-input-number class=" ml20" style="width: 230px;" placeholder="请输入排序号(越大越靠前)"
							v-model="form.sort" :precision="0" :min="0"/>
					</div>
					<div v-if="form.pid==0" class="mt15">
						<div class="flex center">
							<upload-img v-model="form.indexthumb"></upload-img>
						</div>
						<div class="flex center mt8">
							<div class="ft12 ftw400 cl-notice">首页分类图：建议上传200x200px</div>
						</div>
					</div>
					<div v-if="form.pid==0" class="mt20">
						<a-radio-group v-model="form.indexstatus">
							<a-radio :value="1">
								首页显示
							</a-radio>
							<a-radio :value="0">
								首页隐藏
							</a-radio>
						</a-radio-group>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import uploadImg from '../../../../../../components/upload/upload.vue';
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			project_type_id: {
				type: Number,
				default: 0,
			}
		},
		components: {
			uploadImg,
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					icon: '',
					name: '',
					sort: '',
					pid:0,

                    indexthumb:'',
                    indexstatus:0,
				},
				category:[]
			}
		},
		computed: {
			getTitle() {
				if (this.project_type_id == 0) {
					return "添加分类";
				} else {
					return "编辑分类"
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {

			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/showProjectType',{
					project_type_id:this.project_type_id,
				}).then(res=>{
                    console.log(res);
					if(res.detail){
						this.form=res.detail;
						console.log(this.form);

					}
					if(res.category){
					    this.category=res.category;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('platform/saveProjectType',{
					project_type_id:this.project_type_id,
					name:this.form.name,
					icon:this.form.icon,
					sort:this.form.sort,
					pid:this.form.pid,
                    indexthumb:this.form.indexthumb,
                    indexstatus:this.form.indexstatus,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})

				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})

			},

		}
	}
</script>

<style>

</style>
